
import { computed, defineComponent, ref } from 'vue'

import RenewCourse from './RenewCourse.vue'
import ModalCriticalRenew from './ModalCriticalRenew.vue'

import { Stream } from '@web/store/types/modules/stream'
import STREAM_STATUS, { USER_COURSE_STREAM_FREEZE_STATUS } from '@web/consts/StreamStatus'
import i18n from '@web/plugins/i18n'
import Time from '@web/common/Time'
import useStatusRenew from '@web/composition/useStatusRenew'
import LocalStorage from '@web/common/LocalStorage'
import { EXTEND_MODEL } from '@web/consts/CourseExtentends'
import Analytics from '@web/services/Analytics/Analytics'
import urlParse from 'url-parse'
import { getCountDayRemaining, getUrlWithAuthParams } from '@web/common/Utils'
import { useRouter } from 'vue-router'
import useGetImageWithSize from '@web/composition/useGetImageWithSize'
import { useStore } from '@web/store'

const DAY = 86400000

export default defineComponent({
  name: 'Course',
  components: {
    RenewCourse,
    ModalCriticalRenew
  },
  props: {
    stream: {
      type: Object as () => Stream,
      required: true
    },
    course: {
      type: Object as () => Course,
      required: true
    },
    page: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()
    const courseCaption = ref(props.course.caption || props.course.title)

    const showRenewCourse = ref(false)
    const showModalCritical = ref(false)

    const {
      isWarning,
      isCritical
    } = useStatusRenew(props.stream)

    const subscription = computed(() => props.stream.paymentSubscriptionId ? store.getters['subscription/subscription'](props.stream.paymentSubscriptionId) : null)

    const courseImage = computed(() => useGetImageWithSize({ image: props.course.image, width: 370 * 2 }))
    const progress = props.stream.progress.progress || 0
    const canContinueCourse = computed(() => props.stream.status === STREAM_STATUS.OPEN)
    const continueUrl = computed(() => canContinueCourse.value
      ? {
          name: 'calendar',
          params: {
            courseId: props.stream.courseId,
            streamId: props.stream.id
          }
        }
      : '')
    const savedTime = {
      _key: `acProject_modal_critical_${props.stream.id}`,
      get (): number {
        return Number(LocalStorage.get(this._key) || 0)
      },
      set (newTime: string): void {
        LocalStorage.set(this._key, newTime)
      }
    }

    const isShowAccessText = computed(() => {
      if (subscription.value?.isLifetime) {
        return false
      }
      if (props.stream.isOptionalPurchase) {
        return props.stream.status === STREAM_STATUS.ENDED || props.stream.status === STREAM_STATUS.STOPPED || props.stream.status === STREAM_STATUS.NOT_STARTED
      }

      return true
    })
    const isShowAccessTextLifeTime = computed(() => {
      if (!subscription.value?.isLifetime) {
        return false
      }
      const hasCourse = subscription.value?.raw.content.courses.find(c => c.id === props.stream.courseId)
      return hasCourse
    })

    const access = computed<{text: string; date?: string;}>(() => {
      if (props.stream.status === STREAM_STATUS.NOT_STARTED) {
        return {
          text: i18n.global.t(`stream.${props.stream.status}`),
          date: Time(props.stream.startDate).format('DD.MM.YYYY')
        }
      }

      if (props.stream.status === STREAM_STATUS.FREEZE && props.stream.userCourseStreamFreeze?.status === USER_COURSE_STREAM_FREEZE_STATUS.active) {
        return {
          text: i18n.global.t(`stream.${props.stream.status}`),
          date: Time(props.stream.userCourseStreamFreeze?.endDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
        }
      }

      if (props.stream.status === STREAM_STATUS.STOPPED) {
        return props.stream.isOptionalPurchase
          ? {
              text: i18n.global.t(`stream.${STREAM_STATUS.ENDED}`)
            }
          : {
              text: i18n.global.t(`stream.${props.stream.status}`),
              date: Time(props.stream.endDate).format('DD.MM.YYYY')
            }
      }

      if (props.stream.status === STREAM_STATUS.ENDED) {
        return {
          text: i18n.global.t(`stream.${props.stream.status}`)
        }
      }

      if (isCritical || isWarning) {
        return {
          text: i18n.global.t('stream.isCritical'),
          date: getCountDayRemaining(props.stream).toString()
        }
      }

      return {
        text: i18n.global.t(`stream.${props.stream.status}`),
        date: Time(props.stream.endDate).format('DD.MM.YYYY')
      }
    })

    const scores = computed<Score[]>(() => Object.values(props.stream.scores || {}))
    const accruedScores = computed<AccruedScore[]>(() => Object.values(props.stream.accruedScores || {}))
    const points = computed(() => scores.value.reduce((count, score) => count + (score.isEnabled ? score.amount : 0), 0) || 0)
    const accruedPoints = computed(() => {
      return accruedScores.value.reduce((count, aScore) => {
        const isEnabled = scores.value.find(score => score.type === aScore.type)?.isEnabled || false
        return count + (isEnabled ? aScore.amount : 0)
      }, 0)
    })
    const pointsText = computed(() => {
      if (props.course.gamificationEnabled && Number(points.value)) {
        return i18n.global.t('receivedPoints', { points: `${accruedPoints.value}/${points.value}` })
      }

      return ''
    })

    function goToContinue (): void {
      router.push(continueUrl.value)
    }

    function onShowRenewModal () {
      showModalCritical.value = false

      if (props.course.extendModel !== EXTEND_MODEL.DISABLED_EXTEND) {
        Analytics.send({
          element_type: 'other',
          element_id: 'prolongation',
          action: 'click'
        })
        showRenewCourse.value = true
      }
    }

    function remindLater () {
      Analytics.send({
        element_type: 'other',
        element_id: 'remindLater',
        action: 'click'
      })
      savedTime.set(Date.now().toString())
      router.push(continueUrl.value)
    }

    function openCourse () {
      if (props.stream.status === STREAM_STATUS.NOT_STARTED) {
        return
      }

      const isShowCriticalPopup = !(savedTime.get() && ((Date.now() - savedTime.get()) < DAY))
      if (!canContinueCourse.value) {
        if (props.stream.isOptionalPurchase) {
          router.push({ name: 'subscriptions', params: { warning: 'true' } })
        } else if (props.course.extendModel === EXTEND_MODEL.NEW_BUY_EXTEND && props.course.extendPageUrl) {
          const urlInstance = urlParse(props.course.extendPageUrl, true)
          urlInstance.set('query', {
            ...urlInstance.query,
            utm_source: 'lk',
            utm_medium: 'extend_button'
          })
          window.open(getUrlWithAuthParams(urlInstance.toString()), '_blank')?.focus()
        } else {
          onShowRenewModal()
        }
      } else if (isCritical && isShowCriticalPopup && props.course.extendModel === EXTEND_MODEL.STANDARD_EXTEND && !props.stream.paymentSubscriptionId) {
        showModalCritical.value = true
      } else {
        router.push(continueUrl.value)
      }
    }

    function onCloseRenewCourse () {
      showRenewCourse.value = false
    }

    return {
      courseCaption,
      subscription,
      courseImage,
      progress,
      showRenewCourse,
      showModalCritical,
      isShowAccessText,
      isShowAccessTextLifeTime,
      access,
      pointsText,
      isCritical,
      isWarning,
      goToContinue,
      remindLater,
      openCourse,
      onShowRenewModal,
      onCloseRenewCourse
    }
  }
})
