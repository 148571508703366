import { computed } from 'vue'

import { useStore } from '@web/store'
const store = useStore()

function useCatalog () {
  const items = computed(() => store.getters['catalog/items'])
  const totalCount = computed(() => store.getters['catalog/getTotalCount'])

  async function fetchData (payload?: { perPage?: number, page?: number }) {
    await store.dispatch('catalog/fetchCachedItems', { perPage: payload?.perPage, page: payload?.page })
  }

  async function fetchDataForce (payload?: { perPage?: number, page?: number }) {
    await store.dispatch('catalog/fetchItems', { perPage: payload?.perPage, page: payload?.page })
  }

  return {
    items,
    totalCount,
    fetchData,
    fetchDataForce
  }
}

export default useCatalog
